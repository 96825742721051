<template>
	<div class="awards">
		<div style="padding: 90px 0px 50px 130px;">
			<img src="../../assets/img/awardsTitle.png" style="display: block;width: 80%;" alt="">
		</div>
		<div class="jz">
			<img :src="img" alt="">
		</div>
		<img src="../../assets/img/zbfw.png" style="width: 75%;margin: 20px auto 0px;display: block;" alt="">
	</div>
</template>

<script>
	import {
		uploadData
	} from '@/api/index.js'
	export default {
		name: 'awards',
		data() {
			return {
				img: ''
			}
		},
		mounted() {
			this.getAwards()
		},
		methods: {
			getAwards() {
				uploadData({
					token: localStorage.getItem('token')
				}).then(res => {
					if (res.data.code == 1) {
						console.log(res.data.data, "奖状");
						this.img = res.data.data
					} else {
						this.$toast(res.data.msg)
					}
					console.log(res)
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.awards {
		width: 100%;
		height: 100%;
		min-height: 100vh;
		background: url(../../assets/img/awardsbg.png) no-repeat center;
		background-size: 100% 100%;
		padding-bottom: 30px;
		.jz {
			width: 90%;
			height: 500px;
			background-color: #fff;
			border-radius: 10px;
			margin: 200px auto;

			img {
				width: 100%;
				height: 100%;
			}
		}

	}
</style>
